.ck-content {
  line-height: 1.5;
  word-break: break-word;
  min-height: 500px;
  ol,
  ul {
    padding-inline-start: 40px;
    li {
      list-style: inherit;
    }
  }
}

.ck.ck-editor__main > .ck-editor__editable.ck-column-resize_disabled {
  background-color: #f5f5f5;
}
